import * as $ from "jquery";
import * as Popper from "popper.js";
import * as Slick from "slick-carousel";

import "bootstrap";
import svg4everybody from "svg4everybody";
import objectFitImages from "object-fit-images";
import flickr from "./flickr";

window.$ = $;
window.jQuery = $;
window.Popper = Popper;
window.Slick = Slick;

svg4everybody();
objectFitImages();
