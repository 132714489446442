import "../styles/main.scss";
import "./plugins";



$(document).ready(function() {
	$('.o-read-more-btn').click(function() {
	  $(this).toggleClass( "active" );
	  if ($(this).hasClass("active")) {
	    $(this).text("Read less");
	  } else {
	    $(this).text("Read more");
	  }
	});
	$('.o-plus-more-btn').click(function() {
	  $(this).toggleClass( "active" );
	  if ($(this).hasClass("active")) {
	    $(this).text("- less");
	  } else {
	    $(this).text("+ more");
	  }
	});
});