$(function() {
    var apiKey = '17522b61aa8e50a43b6b11cbee165ab3';
    var userId = '101256334@N04';
    var perPage = '10';
    var showOnPage = '1';

    $.getJSON('https://api.flickr.com/services/rest/?format=json&method=' +
        'flickr.photos.search&api_key=' + apiKey + '&user_id=' + userId +
        '&per_page=' + perPage + '&jsoncallback=?',
        function(data) {
            $.each(data.photos.photo, function(i, rPhoto) {
                var basePhotoURL = '//farm' + rPhoto.farm + '.static.flickr.com/' + rPhoto.server + '/' + rPhoto.id + '_' + rPhoto.secret;

                var thumbPhotoURL = basePhotoURL + '_m.jpg';

                var photoString = '<li class="col-md-4 mb-4"><a target="_blank" title="' + rPhoto.title + '" href="//www.flickr.com/photos/' +
                    userId + '/' + rPhoto.id + '" class="d-block img-thumbnail h-100"><img class="o-fit-cover" width="100%" height="150" src="' + thumbPhotoURL + '" alt="' +
                    rPhoto.title + '"/></a></li>;'

                $(photoString).appendTo("#flickr");
            });                              
        });

    setTimeout(function() {
        if ($('#flickr').length) {
            $('#flickr').show();
        }
    }, 3000);
});
